const serverinstantservicesData2 = [
  {
    key: 1,
    maintexttitle: "ग्राहक",
    maintitle1: "ग्राहक माहिती ",
    maintitle2: "संख्या ",
    itemcount: [
      {
        key: 2,
        startcount: 0,
        endtcount: 1000,
        durationcount: 30,
        countdecription1: "आनंदी ",
        countdecription2: "ग्राहक ",
        counttitle: "",
      },
      {
        key: 3,
        startcount: 0,
        endtcount: 2000,
        durationcount: 30,
        countdecription1: "ग्राहक सामील आणि",
        countdecription2: "आनंदी ग्राहक ",
        counttitle: "",
      },
    ],
  },
];

export default serverinstantservicesData2;
