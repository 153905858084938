const opportunityserverData = [
  {
    key: 1,
    maintexttitlecontent: "ई-सेवा केंद्र",
    maintitletext1: " जगदंब ",
    maintitletext2: "Website",
    videotitle: "Website",
    videoiframe:"web"
      // "https://www.youtube.com/embed/FEjNov0Vf6M?si=qDDtGRb0yzbgzVR4",
  },
];

export default opportunityserverData;
// ns25.domaincontrol.com
// ns26.domaincontrol.com