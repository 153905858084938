const servicesData = [
    {
      key: 1,
      servicestitle: "service",
      maintitle:"Click Here",
      rentagrementtitle: "Online Rent Agreement",
      Noticettitle: "Notice of Intimation",
      servicePersonalDocument: [
        {
          key: 2,
          personaltext: " Personal Document",
          personaldocumentList: [
            "Authority Letter",
            "Authorization letter of Adoption",
            "Authority to Adopt",
            "Adoption Deed",
            "Will Deed",
            "Cancellation of Will",
            "Note or Memorandum",
            "Entry of Memorandum of Marriage",
            "Divorce",
            "Dissolution of Marriage",
            "Composition Deed",
            "Family Arrangement",
            "Family Management Deed",
            "Affidavit",
          ],
        },
        {
          key: 3,
          Propertytext: "Property Document",
          PropertydocumentList: [
            "Donation Deed",
            "Gift Deed",
            "Release Deed",
            "Correction Deed",
            "Irrevocable Power of Attorney",
            "Special Power of Attorney",
            "General Power of Attorney",
            "Power of Attorney",
            "Appointment In Execution of Power",
            "Exchange",
            "Deed of Exchange",
            "Cancellation Deed",
            "JOINT VENTURE",
            "Treaty of Transfer of Clan Rights",
            " Assignment of Development Rights",
            "Transfer of Development Rights",
            "Development Agreement",
            "Supplemental Agreement",
            "Hire Purchase Agreement",
            "Deed",
            "Lease Deed",
            "36-A-Leave and License",
            "Leave and License",
            "Rent Agreement",
            "Agreement To Lease",
            "Assignment of Lease",
            "Transfer of Lease",
            "Agreement of Transfer of Tenancy",
            "Transfer of Tenancy Rights",
            "Transfer Deed of Lease",
            "Surrender of Lease",
            "Agreement",
            "Agreement For Sale",
            "With Possession Agreement For Sale",
            "Without Possession Agreement For Sale",
            "Articles of Agreement",
            "Agreement to Sale of Shop",
            "Agreement to Sale of Office",
            "Agreement to Sale of Flat",
            "Agreement of Sale",
            "Agreement to Sale",
            "Agreement to Assignment",
            "Conveyance",
            "Agreement of Transfer",
            "Transfer Agreement",
            "Promissory Note",
            "Sale Deed",
            "Apartment Deed",
            "Deed of Apartment",
            "Assignment+B45 Deed",
            "Deed of Assignment",
            "Deed of Conveyance",
            "Deed of Transfer",
            "Purchase Deed",
            "Forever Happy Purchase Deed",
            "Repurchase Deed",
            "Happy Purchase Deed",
            "Reverse Purchase Deed",
            "Contract of Alternative Premises",
            "Partial Sale of Undivided Share",
            "Complete Sale of Undivided Share",
            " Partition Deed",
            "Conditional Purchase Deed",
            "Term Purchase Deed",
            "Possession Receipt",
            "Advance Receipt",
            "Indenture",
            "Consent Deed",
            "Declaration Deed",
            "Confirmation Deed",
            "Appraisement OR Valuation",
            "Usurpation",
            "Deposit of Title Deed",
            "Entitlement Deed",
            "Transfer",
            "Letter of Allotment of Shares",
            "Certificate of Sale",
            "Sales Receipt",
            "Sale certificate",
            "Jamin Kadbah",
            "Disclaimer Deed",
            "Regulation Deed",
            "Trustee Articles",
            "Trust Deed",
            "Memorandum of Understanding Agreement",
          ],
        },
        {
          key: 4,
          Loantext: "Loan Document",
          LoandocumentList: [
            "Notarial Act",
            "Indemnification Bond",
            "Over Burdened Mortgage",
            "Further Charge",
            "Acknowledgement/Possession Receipt",
            "Surety Bond",
            "Protest of Bill or Note",
            "Hypothecation",
            "Crop Mortgage",
            "Crop Mortgage",
            "Settlement",
            "Mortgage with Possession",
            "Possession Mortgage",
            "Najar Mortgage",
            "Mortgage Deed",
            "Notice Of Intimation",
            "Equitable Mortgage Deed",
            "Reconvenience",
            "Security Bond",
            "Indemnity Bond",
            "Guarantee Letter",
            "Bond",
          ],
        },
        {
          key: 5,
          Businesstext: "Business Document",
          BusinessdocumentList: [
            "Limited Liability Partnership",
            "Dissolution of Partnership",
            "Partnership Deed",
            "Work Contract",
            "Shipping Order",
            "License",
            "Incorporation Schedule of Company",
            "Delivery Orders in Respect of Goods",
            "Administration Bond",
            "Articles of Clerkship",
            "Warrant for Goods",
            "Articles of Association of a Company",
            "Apprenticeship Deed",
            "Record of Transaction (Electronic or Other)",
            "Customs Bond or Excise Duty Bond",
            "Clearance List (Any type of Yarn)",
            "Clearance List of Purchase of Spilled Grains",
            "Clearance List Gold and Silver wood",
            "Clearance List (Cotton ....)",
          ],
        },
        {
          key: 6,
          Othertext: " Other Document",
          OtherdocumentList: [
            "Share Warrants",
            "Exemption",
            "Charter",
            "Award",
            "Common Rules Article",
            "Respondentia Bond",
            "Navigation Article",
            "Note of Protest by Master of a Ship",
            "Order to Pay",
            "Duplicate Copy",
            "Copy",
            "Certificate",
            "Clearance List",
            "Bottomry Bond",
            "Protest By The Master of a Ship",
            "Codiseal",
            "Receipt",
            "Deed of Poll",
            "Restless Letter",
          ],
        },
      ],
    },
  ];
  
  export default servicesData;
  