import { faEdgeLegacy } from "@fortawesome/free-brands-svg-icons";
const efficlencyData = [
  // Your card data goes here
  // नोंदणी व मुद्रांक विभाग महाराष्ट्र शासन
  {
    maintitle: "महाराष्ट्र व भारत सरकारचा उपक्रम",
    pareghrap1: " सर्व प्रकारच्या शासकीय दाखल्यांसाठी",
    pareghrap2: " लागणाऱ्या कागदपत्रांची यादी",
    title: " उत्पन्न दाखला कागदपत्रे",
    content:
      "रहिवाशी दाखलाग्रामसेवक) ● तलाठी उत्पन्न • नोकरी पासुन उ ● पेन्शन असलेस पासबुक ● आधारकार्ड, रेशनकार्ड झेरॉक्स • शेती पासुन उत्पन्न असलेस ७/१२ उतारा ● आयकर विवरण पत्र/ पगार दाखला ● घोषणा पत्र ● फोटो १",
    icons: faEdgeLegacy,
  },
  {
    title: "नोंदणीकृत भाडे करार",
    content:
      "नोंदणीसाठी मालकाची कागदपत्रे: 1] आधार कार्ड 2] पॅन कार्ड 3] वीज बिल ",
    content2: " नोंदणीसाठी भाडेकरू कागदपत्रे: 1] आधार कार्ड 2] पॅन कार्ड  ",
    content3: " नोंदणीसाठी दोन साक्षीदार कागदपत्रे: 1] आधार कार्ड  ",
    icons: faEdgeLegacy,
  },
  {
    title: "ऑनलाईन मॅरेज रजिस्ट्रेशन",
    content:
      " ● आधार कार्ड ● शाळा सोडल्याचा दाखला ● जन्मदाखला ●पत्ता अव्हिडन्स ●रेशन कार्ड ● 3 साक्षीदार त्याची आधारकार्ड सागली मूल दस्तावेज  ",
    icons: faEdgeLegacy,
  },
  {
    title: "ऑनलाईन भाडे करार पत्र ",
    content:
      " ● खरेदी - विक्रि दस्त ● सारण गहाण दस्त ● सर्व प्रकारचे नोटरी ऑफिडेटिट अन्न व औषध प्रशासन / प्रतिज्ञा पत्र करून मिळतील ● ऑनलाईन स्टॅम्प व डाटा इंट्री करून मिळेल ",
    icons: faEdgeLegacy,
  },
  {
    title: "GST साठी प्राथमिक कागदपत्रे",
    content:
      "*पॅन झेरॉक्स * आधार कार्ड झेरॉक्स * पत्त्याचा पुरावा व्यवसाय (मालकाची एनओसी भाड्याने घेतल्यास) * विद्युत बिल / नगरपालिका कर चलन * छायाचित्र * व्यापार नाव * चालू कायद्याचा रद्द केलेला चेक * विशिष्ट आयटम तपशील * अधिकृत पत्र (मालक व्यतिरिक्त इतर असल्यास) * ई - मेल आयडी ",
    icons: faEdgeLegacy,
  },
  {
    title: "कोल्हापूर महानगरपालिका व्यवसाय परवाना कागदपत्रे",
    content:
      "*जागा मालक आधार कार्ड आणि pan card * भाडेकरू आधार कार्ड आणि pan card * उतारा किंवा प्रॉपर्टी कार्ड किंवा सात बारा * फाळा पावती * दुकानाचे नाव * चतू सीमा * भाडे रक्कम * Deposit amount",
    content2:
      "*शहरासाठी * बांधकाम पावरणा * मंजूर नकाशा * भोगवटा प्रमाणपत्र * इमारत फोटो शटर उघडून * दुकानाचा फोटो शटर उघडून * फार्मासिस्ट सर्टीफिकेट",
    icons: faEdgeLegacy,
  },
  {
    title: "नवीन किंवा विभक्त रेशनकार्ड",
    content:
      "* स्वतः च्यानावे लाईटबील, घरफाळा पावती * तहसिलदार उत्पन्न दाखला * धान्यदुकानदार यांचा विभक्त असल्याचा दाखला * जुन्या रेशनकार्डची झेरॉक * बँकपासबुक झेरॉक्स, फोटो * अ‍ॅफिडेव्‍हिट * सर्वांचे आधारकार्ड झेरॉक्स व फोटो",
    icons: faEdgeLegacy,
  },
  {
    title: "३०% महिला आरक्षण नॉन क्रिमीलेयर दाखला ",
    content:
      "● रहिवाशी दाखला (सरपंच/नगरसेवक/ ग्रामसेवक) ● तलाठी ३ वर्ष उत्पन्नाचा दाखला ● शाळा सोडल्याचा दाखला/ बोनाफाईड ● ऑफडेव्हिट ● नोकरी पासून उत्पन्न असलेस १६ में फॉर्म ● पेन्शन असलेस पासबुक झेरॉक्स ● रेशन कार्ड आधारकार्ड फोटो ● नॉन क्रिमीलेयर जातीसाठी हवे असल्यास जातीचा दाखला आवश्यक",
    icons: faEdgeLegacy,
  },
  {
    title: "जातीचा दाखला कागदपत्रे",
    content:
      "* तलाठी रहिवाशी व जातीचा दाखला * स्वता:चा शाळा सोडलेचा दाखला/बोनाफाईड / जन्मनोंद दाखला * वडील / आजोबा / पणजोबा / चुलत आजोबा / आत्या यांचा शाळा सोडलेचा/ बोनाफाईड दाखला (मयत असलेस मृत्यु नोंद) * महसुल पुरावा ७/१२ किंवा घराचा उतारा प्रॉपर्टी कार्ड * एस.सी. १९५०, एन.टी.-१९६१, ओ.बी.सी. १९६७ पूर्वीचा जात पुरावा * कुणबी साठी १९२० ते १९३० मधील पुरावा * आधारकार्ड झेरॉक्स / रेशनकार्ड झेरॉक्स / फोटो १",
    icons: faEdgeLegacy,
  },
  {
    title: "दुबार / हरवलेले रेशनकार्ड",
    content:
      "● धान्य दुकानदार यांचा रेशन कार्ड खराब / हरवलेला दाखला ● हरवलेले असल्यास पोलिस स्टेशनचा दाखला ● तहसिलदार उत्पन्न दाखला ● खराब साठी मूळ रेशनकार्ड/ हरवले असल्यास झेरॉक्स ● सर्वांचे आधारकार्ड झेरॉक्स ● बँकपासबुक झेरॉक्स, फोटो अ‍ॅफिडेव्‍हिट ● धान्यदुकानदार यांचा अबक दाखला ",
    icons: faEdgeLegacy,
  },
  {
    title: "पॅन कार्ड",
    content:
      "* २ फोटो, आधारकार्ड झेरॉक्स* जन्मतारखेचा पुरावा( १०/१२ बोर्ड सर्टिफिकेट / अ‍ॅफिडेव्‍हिट)",
    icons: faEdgeLegacy,
  },
  {
    title: "आधार कार्ड",
    content:
      "* नविन आधार कार्ड ० ते ५ वयोगट जन्म दाखला आवश्यक * नविन आधारकार्ड, नावात बदल,पत्यामधे बदल, जन्मतारखे मध्ये बदल,फोटो बदलुन मिळेल.",
    icons: faEdgeLegacy,
  },
  {
    title: "गॅझेट (राजपत्र)",
    content:
      "* नावात बदल ( वेगळी नावे असणारे दोन पुरावे)* जन्मतारखेत बदल (जुनी जन्मतारीख व नवीन जन्मतारीख यांचे पुरावे) * शाळा सोडल्याचा दाखला *अ‍ॅफिडेव्‍हिट * आधारकार्ड / रेशनकार्ड झेरॉक्स, फोटो १ ",
    icons: faEdgeLegacy,
  },
  {
    title: "रेशनकार्डावरील नाव वाढवणे ",
    content:
      "* मुलांची नावे वाढवण्यासाठी (जन्म दाखला / बोनाफाईड, मुळ रेशनकार्ड) * पत्नीचे नाव वाढवणेसाठी (विवाहनोंद दाखला व जुन्या रेशनकार्डवरील कमी केलेला दाखला) ● बँकपासबुक झेरॉक्स, फोटो • धान्यदुकानदार यांचा अबक दाखला ",
    icons: faEdgeLegacy,
  },
  {
    title: "रेशनकार्डावरील नाव कमी करणे ",
    content:
      "* विवाह झालेले नाव कमी करणेसाठी (विवाहनोंद दाखला व मूळ रेशनकार्ड) * नोकरी किंवा इतर कारणास्तव (तेथील तलाठी रहिवासी दाखला किंवा नोकरीचे पत्र व मूळ रेशन कार्ड) * मयत नाव कमी करणेसाठी (मृत्युनोंद दाखला) * बँकपासबुक झेरॉक्स, फोटो * धान्यदुकानदार यांचा अबक दाखला ",
    icons: faEdgeLegacy,
  },
  {
    title: "शॉप ॲक्ट लायसन्स",
    content:
      "• दुकानाचा मराठीतील बो * लाईट बिल (३ महिन्याच / घरफाळा पावती ● आधारकार्ड ● फोटो १ ",
    icons: faEdgeLegacy,
  },
  {
    title: "डोमेसाइल/डोंगरी दाखला/ स्थानिक रहिवाशी दाखला",
    content:
      "● रहिवाशी दाखला (सरपंच/नगरसेवक/ग्रामसेवक) ● शाळा सोडल्याचा दाखला / जन्म नोंद दाखला ● दहावी /बारावी प्रमाणपत्र किंवा मार्कलिस्ट ● १५ वर्षाचा रहिवाशी पुरावा (लाईट बील पाणी बील, मतदार यादी) ● आधारकार्ड / रेशनकार्ड झेरॉक्स फोटो ● विवाहित महिला असलेस विवाह नोंदणी दाखला किंवा गॅझेट आवश्यक ● अर्जदार १८ वर्षाखालील असलेस वहिलांचे आधार कार्ड व फोटो आवश्यक ",
    icons: faEdgeLegacy,
  },
  {
    title: "उत्पन्नाचा दाखला",
    content:
      "■ आधार कार्ड झेरॉक्स ■ रेशन कार्ड झेरॉक्स ■ रहिवाशी दाखला ■  एक फोटो ■  शेतकऱ्यांसाठी ७/१२ किंवा ८ अ ■ नोकरदारांसाठी १६ नं. फॉर्म",
    icons: faEdgeLegacy,
  },
  {
    title: "जातीचा दाखला",
    content:
      "■ शाळा सोडलेचा दाखला (अर्जदार) ■ शाळा सोडलेचा दाखला (वडिल) ■ शाळा सोडलेचा दाखला (आजोबा) ■ रेशन कार्ड झेरॉक्स ■ आधार कार्ड झेरॉक्स ■  नगरसेवक किंवा ग्रामसेवक रहिवाशी दाखला ■ एस.सी., एस.टी. असेल तर १९५० पूर्वीचा ओ.बी.सी./मराठा असेल तर १९६७ पूर्वीचा एन.टी. असेल तर १९६१ पूर्वीचा महसूल पुरावा. ■  घरातील नात्यातील कोणाचाही जातीचा ■ दाखला असेल तर झेरॉक्स प्रत ■ दोन फोटो. ",
    icons: faEdgeLegacy,
  },
  {
    title: "डोमिसाइल सर्टिफिकेट",
    content:
      "■ आधार कार्ड झेरॉक्स (अर्जदार/वडील) ■ रेशनकार्ड झेरॉक्स ■ एक फोटो ■  ग्रामीण ग्रामसेवक रहिवाशी दाखला ■ शहर नगरसेवक रहिवाशी दाखला ■ शाळा सोडलेचा दाखला ■ लाईट बिल / ड्रायव्हिंग लायसन्स ■ १५ वर्षे रहिवाशी पुरावा ■ १८ वर्षे पूर्ण नसलेस वडिलांचा शाळा सोडलेचा दाखला व एक फोटो",
    icons: faEdgeLegacy,
  },
];

export default efficlencyData;
