import React from "react";

const Appheaderlogo = () => {
  return (
    <div className="devmindslogo main-header-devmainds ">
      <div className="devmaindslogo_content">
        <h4>
          <b className="Devmindslogo">जगदंब ई-सेवा केंद्र</b>
        </h4>
      </div>
    </div>
  );
};

export default Appheaderlogo;
